import React from 'react';
import Pill from './pillComponent'
const Pills = ({ list, removePills = null, name = null }) => {
  return (
    <>
      {
        list.map((e, index) => {
          return (<Pill
            e={e}
            key={e}
            index={index}
            removePills={removePills}
            name={name}
          />
          )
        })
      }
    </>
  )
}
export default Pills