import { TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import SearchModal from '../../search/searchModal';
import FormatDisplayName from './formatDisplayName';
// import AddIndependentUser from './addIndependentUser';

function SearchBar({ editMemberData, setSelectedUser, handleNoneOfTheseClick, disable = false }) {
  const [isSearchModalOpen, setSearchModalOpen] = useState(false);
  const [userName, setUserName] = useState('');
  // const [addIndependentUserForm, setAddIndependentUserForm] = useState(false);

  const selectedUserData = (userData) => {
    const displayName = FormatDisplayName(userData?.name, userData?.gautra, userData?.subcaste);
    setUserName(`${displayName}${userData?.village ? `, ${userData?.village}` : ''}`);
    setSelectedUser(userData);
  };
  useEffect(() => {
    const displayName = FormatDisplayName(editMemberData?.name, editMemberData?.gautra, editMemberData?.subcaste);
    setUserName(`${displayName}${editMemberData?.village ? `, ${editMemberData?.village}` : ''}`);
  }, [editMemberData]);
  return (
    <>
      {/* {addIndependentUserForm && <AddIndependentUser onClose={() => setAddIndependentUserForm(false)} />} */}
      {isSearchModalOpen && (
        <SearchModal
          onClose={() => setSearchModalOpen(false)}
          onSelectUser={selectedUserData}
          handleNoneOfTheseClick={handleNoneOfTheseClick}
        />
      )}
      <TextField
        id="search"
        type="search"
        label="Search member by name"
        value={userName}
        onClick={() => setSearchModalOpen(true)}
        sx={{ width: '100%' }}
        disabled={disable}
      />
    </>
  );
}
export default SearchBar;
