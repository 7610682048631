import React, { useState } from 'react';
import Modal from '../../Utilities/Modal';
import { Card, CardBody } from '@paljs/ui/Card';
import SearchForm from './searchForm';
import SearchResultTable from './search_result_table';
import { partitionByVillageFatherMatch } from '../../helpers/searchUtils';

const SearchModal = ({
  onClose,
  gender,
  fields = [],
  //handleNoneOfClick,
  isMukhiyaForm = false,
  selectButton = true,
  onSelectUser,
  handleNoneOfTheseClick,
}) => {
  const [searchResults, setSearchResults] = useState([]);
  const [showSearchResult, setShowSearchResult] = useState(false);
  const [searchParams, setSearchParams] = useState([]);
  const [village, setVillage] = useState('');

  const handleResults = (sendResults, searchParams) => {
    // const sortedResults = partitionByVillageFatherMatch(
    //   sendResults?.search_results,
    //   searchParams,
    //   sendResults?.input_village_details,
    // );
    // setSearchResults(sortedResults);
    setSearchResults(sendResults?.search_results);
    setSearchParams(searchParams);
    console.log('params', searchParams);
    setVillage(sendResults?.input_village_details);
    setShowSearchResult(true);
  };

  return (
    <Modal toggle={onClose}>
      <Card>
        <CardBody>
          {showSearchResult ? (
            <SearchResultTable
              searchResults={searchResults}
              setSelectedUser={onSelectUser}
              onClose={onClose}
              isMukhiyaForm={isMukhiyaForm}
              noneOfThese={true}
              selectButton={selectButton}
              searchParams={searchParams}
              village={village}
              //   handleNoneOfClick={handleNoneOfClick}
              onNoneOfTheseClick={handleNoneOfTheseClick}
            />
          ) : (
            <SearchForm
              sendResults={handleResults}
              fields={[...fields, 'name', 'father', 'gautra', 'village']}
              gender_value={gender}
              heading="Search for user"
              buttonText="Search"
              // isMukhiyaSearch={true}
            />
          )}
        </CardBody>
      </Card>
    </Modal>
  );
};

export default SearchModal;
