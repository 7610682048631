import React from 'react';
import '../editGautraModals/modals.css';

const Pill = ({ e, index = null, removePills = null, name = null }) => {
  return (
    <>

      <div role="button" className="pill pill-query">
        <div className="pill-content">
          {e?.name ? e?.name : e}
          {(removePills && e != name) ?
            (<button
              className="pill-close"
              onClick={() => removePills(index)}
              tabIndex="0"
            >
              <b className="center-btn fas fa-times-circle">X</b>
            </button>
            ) : null
          }
        </div>
      </div>
    </>
  )

}


export default Pill