import styled from 'styled-components';
import ReactDOM from 'react-dom';
import React, { Fragment } from 'react';

const OverlayStyle = styled.div`
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
  justify-content: center;
  align-items: center;
`;

const isBrowser = typeof window !== "undefined"


const Overlay: React.FC<{ children: React.ReactNode }> = (props) => {
  return (
    <Fragment>
      {isBrowser ? ReactDOM.createPortal(
        <OverlayStyle {...props}>{props.children}</OverlayStyle>,
        document.getElementById('overlay-container')!
      ) : ''
      }
    </Fragment>)
};

export default Overlay;
