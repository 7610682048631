import React, { useState } from 'react';
import Modal from '../../../Utilities/Modal';
import { Card, CardContent, Button, CircularProgress } from '@mui/material';
import './styleComponentUtils.css';
import SearchForm from '../../search/searchForm';
import { getMatrimonialProfilesByMobileNo } from '../../../actions/matrimonialAction';
import { toast } from 'react-toastify';
import ExistingProfileCard from './existingProfileCard';
import PillBox from '../../../components/pills/PillBox';

const AddIndependentUser = ({ onClose, showPhoneNoField = false, handleCreate, searchParams }) => {
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const [isVerifying, setIsVerifying] = useState(false);

  const handleVerify = async (e) => {
    if (phoneNumbers.length == 0) {
      toast.error('Please enter atleast one Contact No. to verify');
      return;
    }
    try {
      setIsVerifying(true);
      e.preventDefault();
      const response = await getMatrimonialProfilesByMobileNo(phoneNumbers);
      console.log('response for get all matrimony profile by phone number', response);
      setProfiles(response?.profiles);
      if (response?.profiles?.length > 0) {
        setShowProfile(true);
        setShowAdditionalFields(false);
        toast.error('A profile is already existed for this phone number');
      } else {
        toast.success('No profiles existed, Create New One');
        setShowAdditionalFields(true);
        setShowProfile(false);
      }
      setIsVerifying(false);
    } catch (error) {
      setIsVerifying(false);
      toast.error(`An error occurred while fetching details for matrimony profile, ${error}`);
    }
  };
  const handleSendResults = (data) => {
    if (showPhoneNoField && phoneNumbers.length == 0) {
      toast.error('Please enter atleast one Contact No. to create Independent Profile');
      return;
    }
    const dataObj = {
      contact_number: phoneNumbers,
      ...data,
    };
    handleCreate(dataObj);
  };

  // Function to handle adding a phone number
  const updatePhoneNumbers = (newPhoneNumbers) => {
    console.log('phone no. ', newPhoneNumbers);
    setPhoneNumbers(newPhoneNumbers);
  };

  return (
    <Modal toggle={onClose}>
      <Card>
        <CardContent>
          <h3>Create Independent Profile</h3>
          {showPhoneNoField && (
            <>
              <PillBox
                heading="Phone Numbers"
                list={phoneNumbers}
                updatingData={updatePhoneNumbers}
                name="phoneNumbers"
              />
              {/* <Button onClick={handleVerify} variant="contained" sx={{ mt: 2 }}>
                {isVerifying ? <CircularProgress color="inherit" size={24} /> : 'Search existing profiles'}
              </Button>
              <Button
                type="button"
                variant="outlined"
                className="cancel-button"
                onClick={onClose}
                sx={{ mt: 2, ml: 1 }}
              >
                Cancel
              </Button> */}
            </>
            // </form>
          )}
          {/* {(showAdditionalFields || !showPhoneNoField) && ( */}
          <SearchForm
            search_params={searchParams}
            sendResults={handleSendResults}
            fields={['name', 'father', 'gautra', 'village']}
            independentUser={true}
            buttonText="Create"
            onlyGautraDropDown={true}
          />
          {/* )} */}
          {showProfile && (
            <>
              {profiles?.map((profile, index) => (
                <ExistingProfileCard profile={profile} key={index} edit={true} />
              ))}
              <Button
                type="button"
                variant="outlined"
                className="cancel-button"
                onClick={() => setShowAdditionalFields(true)}
                sx={{ mt: 2, ml: 1 }}
              >
                Create Another Profile for These Mobiles
              </Button>
            </>
          )}
        </CardContent>
      </Card>
    </Modal>
  );
};

export default AddIndependentUser;
