import React, { useState } from 'react';
import './pill.css';
import Pills from './pills';

const PillBox = ({ heading = '', list, updatingData = null, name = null }) => {
  const [allNames, setAllNames] = useState(list);
  const [gautraName, setGautraName] = useState('');

  const createNewPill = (e) => {
    e.preventDefault();
    if (gautraName !== '') {
      setAllNames([...allNames, gautraName]), setGautraName('');
      updatingData([...allNames, gautraName]);
    }
  };

  const clearAllPills = () => {
    const emptyNames = [];
    setAllNames(emptyNames);
    updatingData(emptyNames);
  };

  const removePills = (index) => {
    console.log('removing ' + index);
    const remainingNames = allNames.filter((item, x) => index !== x);
    setAllNames(remainingNames);
    updatingData(remainingNames);
  };

  return (
    <>
      <h4 className="pill-heading">{heading}</h4>
      <div className="b-Container">
        <form className="search-container">
          <input
            className="b-TextInput--lg size-100 custom-input"
            placeholder={'Start typing...'}
            value={gautraName}
            onChange={(event) => setGautraName(event.target.value)}
          />
          <button onClick={createNewPill} className="b-Button--primary add-button">
            add
          </button>
        </form>
        <section>
          {allNames.length > 1 ? (
            <div role="button" className="pill pill-clear" onClick={clearAllPills}>
              <div className="pill-content">Clear All</div>
            </div>
          ) : null}
          <Pills list={allNames} removePills={removePills} name={name} />
        </section>
      </div>
    </>
  );
};

export default PillBox;
